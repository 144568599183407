import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '.';
import { callGetInfo, InfoInputDto, InfoOutputDto } from '../../App/apiWrapper';

export const getAppInfoThunk = createAsyncThunk<InfoOutputDto, InfoInputDto>(
  'app/info',
  async (input) => await callGetInfo(input),
);

export const info = createSlice({
  name: 'info',
  initialState,
  reducers: {
    resetInfo: (state, _: PayloadAction) => {
      state.initialized = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAppInfoThunk.fulfilled, (state, action) => {
      const info = action.payload;
      Object.keys(info).forEach((key) => (state[key] = info[key]));
      state.initialized = true;
      return state;
    });
  },
});

export const { resetInfo } = info.actions;

export default info.reducer;

import { styled, SvgIcon as MuiSvgIcon, SvgIconProps } from '@mui/material';
import * as React from 'react';

const SvgIcon = styled(MuiSvgIcon, {
  name: 'SnakeIcon',
  shouldForwardProp: (_) => true,
})<SvgIconProps>(() => ({
  '& .st0': {
    fillRule: 'evenodd',
    clipRule: 'evenodd',
  },
  enableBackground: 'new 0 0 122.88 118.93',
}));

SvgIcon.defaultProps = {
  x: '0px',
  y: '0px',
  viewBox: '0 0 122.88 118.93',
};

export const SnakeIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      className="st0"
      d="M113.72,7.59l0.18,0.18c-1.32-0.78-3.16-1.05-5.46-1.05c-9.82,0-20.43,6.64-22.68,16.59 c-1.34,1.67-2.87,2.73-4.6,3.17c-4.99,1.28-7.72-2.09-11.51-4.37c-2.1-1.26-4.2-2.15-6.64-2.52c-1.77-0.27-3.63-0.28-5.6,0.04 c-21.9,3.52-21.23,24.5-10.27,38.58c3.2,4.11,6.97,8.13,10.29,11.96c3.41,3.94,6.81,7.58,7.54,11.24c1.01,5.06-2.95,8.86-7.64,7.83 c-4.2-0.92-9.77-4.16-15.75-7.9L20.02,68.89c-4-2.47-7.76-3.47-11.27-2.88c-6.52,1.1-9.87,6.76-8.41,13.13 c2.36,10.28,14.79,15.74,13.89,27.66c-0.34,4.57-3.06,8.61-8.08,12.12c1.37,0.06,2.92-0.5,4.65-1.68c1.81-1.23,3.25-2.48,4.35-3.83 c6.15-7.5,2.21-16.4-1.87-23.55c-0.88-1.55-1.67-3.2-2.14-5.26c-1.11-4.86,0.95-7.54,5.86-5.45c10.97,4.67,34.26,27.62,46.94,26.25 c8.83-0.96,15.76-8.59,17.75-16.94c2.51-10.55-4.68-17.7-10.56-25.98c-3.83-5.39-7.83-10.62-11.51-16.12 c-1.17-1.85-0.86-3.82,0.21-5.17c3.1-3.91,6.62,0.05,9.66,1.65c3.15,1.66,6.36,2.5,9.41,2.82c7.88,0.84,16.44-2.92,21.72-8.76 c9.82-2.94,15.36-13.03,15.73-23.09c0.07-1.93-0.21-3.37-0.83-4.43l0.03,0.03c1.9-1.82,4.71-3.03,7.33-3.77 c-1.68-0.52-3.35-0.81-4.99-0.65c0.32-1.65,0.22-3.31-0.22-4.99C116.59,2.69,115.09,5.61,113.72,7.59L113.72,7.59z M111.53,20.13 c-0.88-0.88-2.32-0.88-3.2,0c-0.89,0.88-0.89,2.32,0,3.2c0.88,0.88,2.32,0.88,3.2,0C112.41,22.45,112.41,21.01,111.53,20.13 L111.53,20.13z M102.97,11.57c-0.89-0.89-2.32-0.89-3.2,0c-0.88,0.88-0.88,2.32,0,3.2c0.88,0.89,2.32,0.89,3.2,0 C103.86,13.89,103.86,12.46,102.97,11.57L102.97,11.57z"
    />
  </SvgIcon>
);

import dayjs, { ManipulateType } from 'dayjs';

/**
 * Returns the number of milliseconds until the start of the next unit of time in the user timezone.
 *
 * @param unit - The unit of time (e.g., 'day', 'hour', 'minute', etc.)
 * @returns The number of milliseconds until the start of the next unit of time
 */
export const getMillisUntilStartOfNext = (unit: ManipulateType): number => {
  const currentTime = dayjs();
  const nextTime = currentTime.add(1, unit).startOf(unit);
  return nextTime.diff(currentTime);
};

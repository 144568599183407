import { DateType } from '../../App/types';
import { ReferenceDataType } from '../../App/apiWrapper';

export interface InfoState {
  date: DateType;
  dateAnnualSign: ReferenceDataType;
  yearAnnualSign: ReferenceDataType;
  initialized: boolean;
}

export const initialState: InfoState = {
  date: null,
  dateAnnualSign: null,
  yearAnnualSign: null,
  initialized: false,
};

import { styled, SvgIcon as MuiSvgIcon, SvgIconProps } from '@mui/material';
import * as React from 'react';

const SvgIcon = styled(MuiSvgIcon, {
  name: 'RoosterIcon',
  shouldForwardProp: (_) => true,
})<SvgIconProps>(() => ({
  '& .st0': {
    fillRule: 'evenodd',
    clipRule: 'evenodd',
  },
  enableBackground: 'new 0 0 115.5 122.88',
}));

SvgIcon.defaultProps = {
  x: '0px',
  y: '0px',
  viewBox: '0 0 115.5 122.88',
};

export const RoosterIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      className="st0"
      d="M8.61,21.72L0,18.18l9.17-3.22C7.6,9.13,16.6,4.73,23.09,0l-1.93,6.28c3.41-0.96,7.47-1.37,11.75-1.61 l-3.46,3.54c2.75,1.08,5.27,2.51,7.72,4.02c-3.56,0.46-6.9,1.15-9.74,2.33c4.01,2.84,5.38,8.98,4.91,17.46L32.26,42 c-0.05,2.9,0.77,4.88,3.46,4.91c12.81,6.02,21.31,7.08,26.87,4.75c1.89-9.12,7.74-17.88,17.3-26.31 c16.22-11.77,32.56-6.28,35.24,13.28c0.6,5.86,0.41,11.51-0.24,17.06c-2.13-10.1-4.31-16.16-6.6-15.61 c1.62,10.11-0.99,19.71-5.79,29.04c-0.21-11.5-1.79-20.57-5.31-26.23c2.12,14.82-0.07,28.82-6.11,42.08 c1.09-14.42,0.05-24.85-3.14-31.3c-1.41,27.11-8.61,41.92-21.8,49.96c-1.02,1.29-1.92,2.65-3.7,3.46l4.26,11.43l2.17,0.77 c3.26,1.16,3.39,3.57,0.16,3.57H48.84c-2.78,0.34-3.24-3.22,0-3.14l13.03-1.45l-3.89-7.48c-1.94-3.72-0.99-1.57-3.89-3.18 c-0.61-0.34-1.2-0.79-1.76-1.35c-1.53-1.53-0.7-1.21-2.99-1.74l-1.52-0.37c-2.2-0.54-1.39-0.16-3.37,0.58 c-0.79,0.3-1.63,0.48-2.54,0.5l-3.86,13.52l2.82,1.05c1.67,0.71,1.22,3.03-1.05,3.06h-20.6c-2.45,0-2.61-3.3,0-3.3l14.08-1.13 l2.9-14.4c-1.53-1.23-2.74-3.1-3.78-5.31c-1.56-0.71-3.1-1.44-4.64-2.2C5.77,85.57-4.96,51.95,9.41,30.9l-2.94,1.74 c-1.44,0.85-1.9,0.02-2.35-1.65C3.41,28.41,5.06,25.29,8.61,21.72L8.61,21.72z M18.73,16.23c1.66,0,3.01,1.35,3.01,3.01 c0,1.66-1.35,3.01-3.01,3.01c-1.66,0-3.01-1.35-3.01-3.01C15.72,17.57,17.07,16.23,18.73,16.23L18.73,16.23z"
    />
  </SvgIcon>
);

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LogoutIcon from '@mui/icons-material/Logout';
import { Typography, useMediaQuery } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../App/hooks';
import { resetLogin } from '../../../features/user/login/loginSlice';
import { selectUserEditOpen } from '../../../features/user/selectUserEditOpen';
import { selectUserProfile } from '../../../features/user/selectUserProfile';
import {
  resetViolation,
  setUserDeleteOpen,
  setUserDeleteStep,
  setUserEditOpen,
} from '../../../features/user/userSlice';
import { resetViolations } from '../../../features/violations/violationsSlice';
import { getAvatar } from '../../../helpers/getAvatar';
import { useAuth } from '../../../hooks/useAuth';
import { GenderIcon } from '../../common/GenderIcon';
import { I18nFragment } from '../../common/I18nFragment';
import { EditAccountDialog } from '../../dialogs/EditAccountDialog';
import { selectTermsOfUseStatus } from '../../../features/ui/selectTermsOfUseStatus';
import { DeleteStep, I18nTypes, TermsOfUseStatus } from '../../../App/types';
import { setError, setShowError } from '../../../features/ui/uiSlice';
import { selectUserDeleteOpen } from '../../../features/user/selectUserDeleteOpen';
import { DeleteAccountDialogWrapper } from '../../dialogs/DeleteAccountDialogWrapper';
import { selectTelegramChannel } from '../../../features/user/selectTelegramChannel';
import { TelegramLink } from './TelegramLink';
import { theme } from '../../../App/theme';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { FormTypography } from '../../common/FormTypography';

interface UserMenuItem {
  id: I18nTypes;
  disabled: boolean;
  divider?: boolean;

  onClick(): void;
}

export const UserAccountInfo: React.FC = () => {
  const { logout } = useAuth();
  const dispatch = useAppDispatch();
  const userProfile = useAppSelector(selectUserProfile);
  const userEditOpen = useAppSelector(selectUserEditOpen);
  const userDeleteOpen = useAppSelector(selectUserDeleteOpen);
  const termsOfUseStatus = useAppSelector(selectTermsOfUseStatus);
  const telegramChannel = useAppSelector(selectTelegramChannel);
  const [anchorElUser, setAnchorElUser] = React.useState<HTMLElement | null>(null);

  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  if (!userProfile) {
    return null;
  }

  const { firstName, lastName, dateOfBirth, gender } = userProfile;

  const handleLogout = () => {
    dispatch(resetLogin());
    dispatch(setShowError(false));
    dispatch(setError(null));
    logout();
  };

  const handleEdit = () => {
    dispatch(setUserEditOpen(true));
  };

  const handleDelete = () => {
    dispatch(setUserDeleteOpen(true));
  };

  const handleCancel = () => {
    dispatch(setUserEditOpen(false));
    dispatch(setUserDeleteOpen(false));
    dispatch(setUserDeleteStep(DeleteStep.CONFIRM_DELETE));
    dispatch(resetViolation());
    dispatch(resetViolations());
  };

  const userMenuItems: UserMenuItem[] = [
    {
      id: 'SH.PG.EDITPROFILE',
      disabled: termsOfUseStatus !== TermsOfUseStatus.ACCEPTED,
      onClick: () => handleEdit(),
    },
    {
      id: 'SH.BTN.DELETEACCOUNT',
      disabled: termsOfUseStatus !== TermsOfUseStatus.ACCEPTED,
      divider: true,
      onClick: () => handleDelete(),
    },
    {
      id: 'SH.PG.LOGOUT',
      disabled: false,
      onClick: () => handleLogout(),
    },
  ];

  return (
    <>
      {telegramChannel && <TelegramLink url={telegramChannel} />}
      {matches && (
        <>
          <Tooltip title={<I18nFragment id={'SH.PG.EDITPROFILE'} />}>
            <span>
              <IconButton
                size="large"
                aria-label="edit current user account"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                sx={{ p: '4px' }}
                onClick={(_) => handleEdit()}
                disabled={termsOfUseStatus !== TermsOfUseStatus.ACCEPTED}
              >
                <EditIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={<I18nFragment id={'SH.BTN.DELETEACCOUNT'} />}>
            <span>
              <IconButton
                size="large"
                aria-label="delete current user account"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                sx={{ p: '4px' }}
                onClick={(_) => handleDelete()}
                disabled={termsOfUseStatus !== TermsOfUseStatus.ACCEPTED}
              >
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Avatar {...getAvatar(`${firstName} ${lastName}`)} />
          <Box sx={{ marginLeft: '7px' }}>
            <Typography variant="body2" align="justify">
              {firstName} {lastName}
            </Typography>
            <Stack direction="row" spacing={1} sx={{ display: 'flex', alignItems: 'end' }}>
              <Typography variant="body2" align="justify">
                {dateOfBirth}
              </Typography>
              <GenderIcon gender={gender} />
            </Stack>
          </Box>
          <Tooltip title={<I18nFragment id={'SH.PG.LOGOUT'} />}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
              sx={{
                paddingRight: '5px',
              }}
              onClick={(_) => handleLogout()}
            >
              <LogoutIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
      {!matches && (
        <>
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <Avatar {...getAvatar(`${firstName} ${lastName}`)} />
          </IconButton>
          <Menu
            sx={{ mt: '40px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {userMenuItems.map((menuItem) => (
              <MenuItem
                key={menuItem.id}
                disabled={menuItem.disabled}
                divider={menuItem.divider ? menuItem.divider : false}
                onClick={(_) => {
                  handleCloseUserMenu();
                  menuItem.onClick();
                }}
              >
                <FormTypography id={menuItem.id} component="span" textAlign="center" />
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
      <EditAccountDialog open={userEditOpen} handleCancel={() => handleCancel()} />
      <DeleteAccountDialogWrapper open={userDeleteOpen} handleCancel={() => handleCancel()} />
    </>
  );
};

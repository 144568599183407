import { styled, SvgIcon as MuiSvgIcon, SvgIconProps } from '@mui/material';
import * as React from 'react';

const SvgIcon = styled(MuiSvgIcon, {
  name: 'CatIcon',
  shouldForwardProp: (_) => true,
})<SvgIconProps>(() => ({
  enableBackground: '0 0 512 512',
}));

SvgIcon.defaultProps = {
  x: '0px',
  y: '0px',
  viewBox: '0 0 512 512',
};

export const CatIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="m429.442 181.673s-5 20 5 30 45 30 40 45-10 25-45 20c0 0-25 0-25 55 0 0 0 25 15 50s45 55 45 55 25 0 25 15v5h-40s-65-40-95-95h-20s-20 0-30 20l-20 55s20-5 25 10v10h-45s-5-40 15-95c.234-.777-17.559-5.5-18.965-5.891-16.523-4.602-33.734-4.844-50.352-.578-5.996 1.543-13.145 1.418-18.313 5.254-5.016 3.719-6.52 10.234-9.309 15.496-3.828 7.227-7.852 14.129-13.148 20.391-.703.82-9.723 10.652-9.914 10.328l15 25s20 0 25 15v10h-30s-45-20-50-50v-45s-30 15-60 40c0 0-5 15 5 25 0 0 5 5 5 15 0 0-24.828 0-25 0-9.918 0-14.074-12.172-14.852-19.969-1.047-10.563 2.449-20.781 6.527-30.371 8.008-18.824 19.461-35.855 33.207-50.961 7.824-8.598 16.582-15.875 25.121-23.699.844-.77-2.297-17.539-2.289-19.07.07-9.684 2.598-18.93 6.547-27.715 4.055-9.012 8.609-18.43 6.078-28.559-2.586-10.34-5.965-20.711-12.836-29.063-7.473-9.086-18.254-14.938-27.922-21.348-12.09-8.012-23.305-17.473-30.555-30.238-9.336-16.465-13.152-36.035-9.602-54.695 2.75-14.422 7.102-30.184 17.766-40.871 3.996-4.004 16.418-13.23 22.809-8.441 0 0 20 15-5 40s0 60 30 70 40 45 40 60c0 0 25-5 50-5s90 10 115 20 55 15 80-10z" />
  </SvgIcon>
);
